import { Box } from "@chakra-ui/react";
import { CookieBanner } from "./cookiesConsent/cookieBanner";
import { Footer } from "./Footer";
import { publicAssetUrl } from "../shared/helpers/urls";

interface Layout2Props {
  children: React.ReactNode;
}

export const Layout2: React.FC<Layout2Props> = ({ children }) => {
  // TODO: use router fallbackLocale
  return (
    <>
      <CookieBanner />
      <Box
        backgroundColor="monochrome.super-light-gray"
        backgroundImage={`url('${publicAssetUrl("/cross_background.svg")}')`}
        backgroundRepeat="repeat"
        backgroundAttachment="scroll"
        itemScope
        itemType="https://schema.org/Person"
      >
        {children}
        <Footer marginTop={12} />
      </Box>
    </>
  );
};
