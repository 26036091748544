import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const InfoIcon = createIcon({
  displayName: "InfoIcon",
  viewBox: "0 0 27 24",
  path: (
    <>
      <g clipPath="url(#infoIcon)">
        <path
          fill="primary.500"
          d="M12 24a12 12 0 1 0 0-24 12 12 0 0 0 0 24Zm-1.875-8.25h1.125v-3h-1.125A1.122 1.122 0 0 1 9 11.625c0-.623.502-1.125 1.125-1.125h2.25c.623 0 1.125.502 1.125 1.125v4.125h.375c.623 0 1.125.502 1.125 1.125S14.498 18 13.875 18h-3.75A1.122 1.122 0 0 1 9 16.875c0-.623.502-1.125 1.125-1.125ZM12 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
        />
      </g>
      <defs>
        <clipPath id="infoIcon">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </>
  ),
  defaultProps: {
    fill: "primary.500",
  },
});
