import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const CustomChevronRightIcon = createIcon({
  displayName: "CustomChevronRightIcon",
  viewBox: "0 0 14 23",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5935 13.6199L13.7148 11.4985L11.5935 9.37723L2.40114 0.184838L0.279817 2.30616L9.4722 11.4985L0.279815 20.6909L2.40114 22.8123L11.5935 13.6199Z"
    />
  ),
  defaultProps: {
    fill: "primary.500",
  },
});
