import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Link,
  Stack,
} from "@chakra-ui/react";
import { useTranslations } from "../shared/i18n/localization";
import parse, { DOMNode, Element, domToReact } from "html-react-parser";
import type { Doctor } from "../shared/types";
import { useContext } from "react";
import { TenantContext } from "../shared/contexts/tenantContext";
import { doctorFullName, doctorWorkplace } from "../shared/helpers/doctors";

interface DoctorProfileFAQProps {
  doctor: Doctor;
}

export const DoctorProfileFAQ: React.FC<DoctorProfileFAQProps> = ({
  doctor,
}) => {
  const tenantConfiguration = useContext(TenantContext);

  const { translate, locale } = useTranslations();
  const translationData = {
    doctorName: doctorFullName(doctor),
    city:
      doctorWorkplace(doctor)?.address?.city ??
      tenantConfiguration.name.toUpperCase(),
    base_price: tenantConfiguration.base_price,
    currency: tenantConfiguration.currency,
  };

  return (
    <Stack spacing={6}>
      <Accordion allowMultiple>
        {tenantConfiguration
          .getDoctorFaqEntries(doctor)
          .map(({ question, answer }) => (
            <AccordionItem _first={{ borderTop: "none" }} key={question}>
              <AccordionButton
                as="h3"
                cursor="pointer"
                _expanded={{
                  bg: "background.light-ink",
                  fontWeight: 600,
                }}
                _hover={{ bg: "background.light-ink" }}
                _focus={{ border: "none" }}
                paddingY={4}
                paddingX={5}
                justifyContent="space-between"
                textAlign="start"
                color="primary.500"
              >
                {translate(question, translationData)}
                <AccordionIcon
                  marginStart={2}
                  color="primary.500"
                  aria-label="details"
                />
              </AccordionButton>
              <AccordionPanel
                marginY={2}
                css={{ ul: { marginLeft: "1.5rem", marginTop: "1rem" } }}
              >
                {parse(translate(answer, translationData), {
                  replace: replaceLinksWithNextLink,
                })}
              </AccordionPanel>
            </AccordionItem>
          ))}
      </Accordion>
      <Button
        as="a"
        variant="secondary"
        width="fit-content"
        marginLeft="auto"
        marginRight={0}
        href={tenantConfiguration.links.doctorProfileFAQonCMS[locale]}
      >
        {translate("doctorProfile.more_faq_link")}
      </Button>
    </Stack>
  );
};

const replaceLinksWithNextLink = (node: DOMNode) => {
  if (node.type === "tag" && (node as Element).name === "a") {
    const { children, attribs } = node as Element;
    return (
      <Link href={attribs.href} fontSize={16}>
        {domToReact(children as DOMNode[])}
      </Link>
    );
  }
};
