import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const FileInfoIcon = createIcon({
  displayName: "FileInfoIcon",
  viewBox: "0 0 27 24",
  path: (
    <>
      <g clipPath="url(#fileInfoIcon)">
        <path
          fill="primary.500"
          d="M0 3c0-1.655 1.345-3 3-3h7.5v6c0 .83.67 1.5 1.5 1.5h6v1.81c-3.464.98-6 4.162-6 7.94a8.25 8.25 0 0 0 3.455 6.717c-.15.024-.3.033-.455.033H3c-1.655 0-3-1.345-3-3V3Zm18 3h-6V0l6 6Zm2.25 4.5a6.75 6.75 0 1 1 0 13.5 6.75 6.75 0 0 1 0-13.5Zm0 4.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Zm-1.5 2.25c0 .413.337.75.75.75v2.25a.752.752 0 0 0-.75.75c0 .413.337.75.75.75H21c.413 0 .75-.337.75-.75a.752.752 0 0 0-.75-.75v-3a.752.752 0 0 0-.75-.75h-.75a.752.752 0 0 0-.75.75Z"
        />
      </g>
      <defs>
        <clipPath id="fileInfoIcon">
          <path fill="#fff" d="M0 0h27v24H0z" />
        </clipPath>
      </defs>
    </>
  ),
  defaultProps: {
    fill: "primary.500",
  },
});
