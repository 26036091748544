import React, { useEffect } from "react";
import type { GetServerSidePropsResult, NextPage, NextPageContext } from "next";
import { DoctorProfileHead } from "../../../../components/DoctorProfileHead";
import { DoctorProfileBody } from "../../../../components/DoctorProfileBody";
import { DoctorProfileHeader } from "../../../../components/DoctorProfileHeader";
import { TenantContext } from "../../../../shared/contexts/tenantContext";
import {
  getFrontendTenantConfigurationById,
  getFrontendTenantConfigurationByName,
} from "../../../../shared/contexts/tenantsConfigurations";
import { Layout2 } from "../../../../components/Layout2";
import { stringFromQueryParam } from "../../../../shared/helpers/urls";
import { getCoreApi } from "../../../../shared/api/coreService/api";
import type { Doctor } from "../../../../shared/types";
import { sendToGoogleTagManager } from "../../../../shared/googleTagManager";
import { getTenantNameFromHost } from "../../../../shared/helpers/tenants";

export interface DoctorPageProps {
  doctor: Doctor;
  tenantId: string;
  baseUrl: string;
}

export const DoctorPage: NextPage<DoctorPageProps> = ({
  tenantId,
  doctor,
  baseUrl,
}) => {
  useEffect(() => {
    sendToGoogleTagManager({
      event: "generic_data",
      tenant_id: tenantId,
    });
  }, [tenantId]);

  return (
    <TenantContext.Provider
      value={getFrontendTenantConfigurationById(tenantId)}
    >
      <DoctorProfileHead
        doctor={doctor}
        tenantId={tenantId}
        baseUrl={baseUrl}
      />
      <Layout2>
        <DoctorProfileHeader doctor={doctor} />
        <DoctorProfileBody doctor={doctor} />
      </Layout2>
    </TenantContext.Provider>
  );
};

export async function getServerSideProps(
  context: NextPageContext
): Promise<GetServerSidePropsResult<DoctorPageProps>> {
  const doctorSlug = stringFromQueryParam(context.query.doctorSlug);
  const tenantName =
    context.req?.headers.host &&
    getTenantNameFromHost(context.req.headers.host);
  const tenantConfig =
    tenantName && getFrontendTenantConfigurationByName(tenantName);

  if (!tenantConfig || !doctorSlug) {
    return { notFound: true };
  }

  const coreApi = getCoreApi();
  const doctor = await coreApi.getDoctor({
    tenantId: tenantConfig.id,
    doctorSlug,
  });

  if (!doctor || !doctor.is_active) {
    return { notFound: true };
  }

  return {
    props: {
      doctor,
      tenantId: tenantConfig.id,
      baseUrl: `https://${context.req?.headers.host}`,
    },
  };
}

export default DoctorPage;
