import { createIcon } from "@chakra-ui/icons";

export const BurgerMenuIcon = createIcon({
  displayName: "BurgerMenuIcon",
  viewBox: "0 0 48 48",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 11H8V14H40V11ZM8 22.5H40V25.5H8V22.5ZM8 34H40V37H8V34Z"
    />
  ),
  defaultProps: {
    fill: "primary.500",
  },
});
