import type { Thing, WithContext } from "schema-dts";
import {
  doctorFullName,
  doctorStreet,
  doctorWorkplace,
} from "../shared/helpers/doctors";

import type { Doctor } from "../shared/types";
import type { Physician } from "schema-dts";

const JsonLd = <T extends Thing>(json: WithContext<T>) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify(json),
    }}
  ></script>
);

export const DoctorStructuredData = ({
  doctor,
  itemUrl,
}: {
  doctor: Doctor;
  itemUrl: string;
}) =>
  JsonLd<Physician>({
    "@context": "https://schema.org",
    "@type": "Physician",
    name: doctorFullName(doctor),
    medicalSpecialty: "Dermatology",
    image: doctor.display_settings?.photo_urls.original,
    url: itemUrl,
    address: {
      "@type": "PostalAddress",
      name: doctorWorkplace(doctor)?.name,
      streetAddress: doctorStreet(doctor),
      postalCode: doctorWorkplace(doctor)?.address.postal_code,
      addressLocality: doctorWorkplace(doctor)?.address.city,
    },
  });
