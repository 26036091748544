import {
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Stack,
  Text,
  useMediaQuery,
  VisuallyHidden,
} from "@chakra-ui/react";
import { WorkplaceMap } from "./WorkplaceMap";
import parse from "html-react-parser";
import React, { useContext, useState } from "react";
import { CustomChevronLeftIcon } from "./icons/CustomChevronLeftIcon";
import { CustomChevronRightIcon } from "./icons/CustomChevronRightIcon";
import { TenantContext } from "../shared/contexts/tenantContext";
import { doctorFullName, doctorWorkplace } from "../shared/helpers/doctors";
import type { Doctor } from "../shared/types";
import { FileInfoIcon } from "./icons/FileInfoIcon";
import { InfoIcon } from "./icons/InfoIcon";
import { LocationIcon } from "./icons/LocationIcon";
import { QuestionMarkIcon } from "./icons/QuestionMarkIcon";
import { useTranslations } from "../shared/i18n/localization";
import { DoctorProfileFAQ } from "./DoctorProfileFAQ";
import { publicAssetUrl } from "../shared/helpers/urls";

interface DoctorProfileBodyProps {
  doctor: Doctor;
}

export const DoctorProfileBody: React.FC<DoctorProfileBodyProps> = ({
  doctor,
}) => {
  const { translate } = useTranslations();

  return (
    <Box
      as="main"
      paddingX={{ base: undefined, lg: 4 }}
      width="fit-content"
      marginX="auto"
    >
      <Section
        title={translate("doctorProfile.header.tabs.intro")}
        icon={
          <FileInfoIcon
            boxSize={{ base: "24px", lg: "34px" }}
            role="img"
            aria-hidden="true"
          />
        }
      >
        <IntroTab />
      </Section>
      <Section
        title={translate("doctorProfile.header.tabs.info")}
        icon={
          <InfoIcon
            boxSize={{ base: "24px", lg: "34px" }}
            role="img"
            aria-hidden="true"
          />
        }
      >
        <WelcomeMessageTab doctor={doctor} />
      </Section>
      <Section
        title={translate("doctorProfile.header.tabs.location")}
        icon={
          <LocationIcon
            boxSize={{ base: "24px", lg: "34px" }}
            role="img"
            aria-hidden="true"
          />
        }
      >
        <MapTab doctor={doctor} />
      </Section>
      <Section
        title={translate("doctorProfile.header.tabs.faq")}
        icon={
          <QuestionMarkIcon
            boxSize={{ base: "24px", lg: "34px" }}
            role="img"
            aria-hidden="true"
          />
        }
      >
        <DoctorProfileFAQ doctor={doctor} />
      </Section>
    </Box>
  );
};

interface SectionProps {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ title, icon, children }) => (
  <Flex
    as="section"
    direction="column"
    backgroundColor="monochrome.white"
    borderRadius={{
      base: "4px 30px 30px 30px",
      xl: "6px 35px 35px 35px",
    }}
    boxShadow="3px 4px 4px rgba(51, 51, 51, 0.1)"
    padding={0}
    marginX={4}
    marginY={{ base: "32px", lg: "40px" }}
    maxWidth={{ base: undefined, md: "1080px" }}
    width={{ base: undefined, md: "full" }}
  >
    <Stack
      direction="row"
      align="center"
      spacing={3}
      width="full"
      height={{ base: "44px", lg: "70px" }}
      paddingX={{ base: 5, lg: 7 }}
      paddingY={4}
      borderRadius={{
        base: "4px 30px 0 0",
        lg: "6px 35px 0 0",
      }}
      backgroundColor="background.light-ink"
      color="primary.500"
    >
      {icon}
      <Heading as="h2">{title}</Heading>
    </Stack>
    <Box padding={{ base: 5, lg: 7 }}>{children}</Box>
  </Flex>
);

const IntroTab: React.FC = () => {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  return isLargerThan800 ? <DesktopIntroTab /> : <MobileIntroTab />;
};

const MobileIntroTab: React.FC = () => {
  const tenantConfig = useContext(TenantContext);
  const { translate } = useTranslations();
  const [subSectionIndex, setSubSectionIndex] = useState(0);

  const subSections = [
    {
      title: translate("doctorProfile.sections.intro.dataSecurity.title"),
      body: translate("doctorProfile.sections.intro.dataSecurity.body"),
    },
    {
      title: translate(
        `doctorProfile.sections.intro.doctor.title.${tenantConfig.name}`
      ),
      body: translate(
        `doctorProfile.sections.intro.doctor.body.${tenantConfig.name}`
      ),
    },
    {
      title: translate("doctorProfile.sections.intro.chatbot.title"),
      body: translate("doctorProfile.sections.intro.chatbot.body"),
    },
    {
      title: translate("doctorProfile.sections.intro.report.title"),
      body: translate("doctorProfile.sections.intro.report.body"),
    },
  ];

  return (
    <>
      {subSections.map(({ title, body }, index) =>
        subSectionIndex === index ? (
          <Stack key={index} align="center">
            <Stack
              direction="row"
              position="relative" // for the numeric badge
              align="center"
              justifyContent="space-between"
              width="full"
              marginY={3}
            >
              <IconButton
                icon={<CustomChevronLeftIcon boxSize="30px" />}
                variant="outline"
                border={0}
                aria-label="back"
                onClick={() => setSubSectionIndex((i) => Math.max(0, i - 1))}
                isDisabled={subSectionIndex <= 0}
                _hover={{ backgroundColor: "initial" }}
              />
              <Image
                src={publicAssetUrl(
                  `/${tenantConfig.name}/profile_intro_0${index + 1}.webp`
                )}
                alt={`${title}, screenshot`}
                width="auto"
                height={220}
              />
              <PhotoNumberBadge
                index={index}
                position="absolute"
                top={5}
                left={index % 2 ? "20%" : undefined}
                right={index % 2 ? undefined : "20%"}
              />
              <IconButton
                icon={<CustomChevronRightIcon boxSize="30px" />}
                variant="outline"
                border={0}
                aria-label="next"
                onClick={() =>
                  setSubSectionIndex((i) => Math.min(i + 1, subSections.length))
                }
                isDisabled={subSectionIndex >= subSections.length - 1}
                _hover={{ backgroundColor: "initial" }}
              />
            </Stack>
            <Heading as="h3" variant="p1" alignSelf="start">
              {title}
            </Heading>
            <Text minHeight={120}>{body}</Text>
          </Stack>
        ) : (
          <VisuallyHidden key={index}>
            <Heading as="h3">{title}</Heading>
            <Text>{body}</Text>
          </VisuallyHidden>
        )
      )}
    </>
  );
};

const DesktopIntroTab: React.FC = () => {
  const tenantConfig = useContext(TenantContext);
  const { translate } = useTranslations();

  const subSections = [
    {
      title: translate("doctorProfile.sections.intro.dataSecurity.title"),
      body: translate("doctorProfile.sections.intro.dataSecurity.body"),
    },
    {
      title: translate(
        `doctorProfile.sections.intro.doctor.title.${tenantConfig.name}`
      ),
      body: translate(
        `doctorProfile.sections.intro.doctor.body.${tenantConfig.name}`
      ),
    },
    {
      title: translate("doctorProfile.sections.intro.chatbot.title"),
      body: translate("doctorProfile.sections.intro.chatbot.body"),
    },
    {
      title: translate("doctorProfile.sections.intro.report.title"),
      body: translate("doctorProfile.sections.intro.report.body"),
    },
  ];

  return (
    <>
      {subSections.map(({ title, body }, index) => (
        <Stack
          key={index}
          direction="row"
          align="center"
          justifyContent="space-between"
        >
          {index % 2 && (
            <Box flex="3">
              <Heading as="h3" variant="p1" alignSelf="start">
                {title}
              </Heading>
              <Text>{body}</Text>
            </Box>
          )}
          <Stack
            direction="row"
            position="relative" // for the numeric badge
            align="center"
            justifyContent="center"
            flex="2"
            marginY={3}
          >
            <Image
              src={publicAssetUrl(
                `/${tenantConfig.name}/profile_intro_0${index + 1}.webp`
              )}
              alt={`${title}, screenshot`}
              width="auto"
              height={440}
            />
            <PhotoNumberBadge
              index={index}
              position="absolute"
              top={5}
              left={index % 2 ? "6%" : undefined}
              right={index % 2 ? undefined : "8%"}
            />
          </Stack>
          {!(index % 2) && (
            <Box flex="3">
              <Heading as="h3" variant="p1" alignSelf="start">
                {title}
              </Heading>
              <Text>{body}</Text>
            </Box>
          )}
        </Stack>
      ))}
    </>
  );
};

interface PhotoNumberBadgeProps extends BoxProps {
  index: number;
}

const PhotoNumberBadge: React.FC<PhotoNumberBadgeProps> = ({
  index,
  ...boxProps
}) => (
  <Stack
    align="center"
    spacing={0}
    direction={index % 2 ? "row-reverse" : "row"}
    {...boxProps}
  >
    <Box
      backgroundColor="primary.500"
      height="2px"
      width={{ base: 12, lg: "90px" }}
    />
    <Button
      as="span"
      borderRadius="full"
      boxSize={{ base: "33px", lg: "54px", xl: "72px" }}
      padding={0}
      color="monochrome.white"
      backgroundColor="primary.500"
      fontSize={{ base: "20px", xl: "28px" }}
      _hover={{ backgroundColor: "primary.500" }}
    >
      {index + 1}
    </Button>
  </Stack>
);

const WelcomeMessageTab: React.FC<{ doctor: Doctor }> = ({ doctor }) => {
  const { translate } = useTranslations();
  return (
    <>
      <Stack spacing={6}>
        <Stack>
          <Heading as="h3" variant="p1">
            {translate("doctorProfile.sections.info.subtitle")}
          </Heading>
          <Box>
            {parse(
              doctor.display_settings?.welcome_message ?? "no description"
            )}
          </Box>
        </Stack>
      </Stack>
      {doctor.display_settings?.external_practice_link && (
        <Flex marginTop={8}>
          <Button
            as="a"
            variant="secondary"
            marginLeft="auto"
            marginRight={0}
            whiteSpace="break-spaces"
            href={doctor.display_settings.external_practice_link}
          >
            {translate("links.externalPracticeLink")}
          </Button>
        </Flex>
      )}
    </>
  );
};

const MapTab: React.FC<{ doctor: Doctor }> = ({ doctor }) => {
  const workplace = doctorWorkplace(doctor);
  return (
    <Stack spacing={6}>
      <Stack>
        <Heading as="h3" variant="p1">
          {workplace?.name}
        </Heading>
        <Box>
          <Text>{doctorFullName(doctor)}</Text>
          <Text>{workplace?.address.line1}</Text>
          {workplace?.address.line2 && <Text>{workplace?.address.line2}</Text>}
          <Text>
            {workplace?.address.postal_code} {workplace?.address.city}
          </Text>
        </Box>
        {workplace?.address && workplace?.longitude && workplace?.latitude && (
          <Box paddingTop={6}>
            <WorkplaceMap
              name={workplace?.name}
              doctorName={doctorFullName(doctor)}
              address={workplace?.address}
              hideAddressBox
              longitude={workplace?.longitude}
              latitude={workplace?.latitude}
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
