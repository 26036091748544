import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const CustomCloseIcon = createIcon({
  displayName: "CustomCloseIcon",
  viewBox: "0 0 25 25",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.253 12.3744L0 22.6274L2.12132 24.7487L12.3744 14.4957L22.6274 24.7487L24.7487 22.6274L14.4957 12.3744L24.7487 2.12132L22.6274 0L12.3744 10.253L2.12132 0L0 2.12132L10.253 12.3744Z"
    />
  ),
  defaultProps: {
    fill: "primary.500",
  },
});
