import React from "react";
import dynamic from "next/dynamic";
import type { AddressData } from "../shared/types";
import { DoctorWorkplaceAddress } from "./DoctorWorkplaceAddress";

const GoogleMap = dynamic(
  () => import("./GoogleMap").then((mod) => mod.GoogleMap),
  { loading: () => <p>Loading map...</p> }
);

interface WorkplaceMapProps {
  name?: string;
  doctorName?: string;
  address: AddressData;
  latitude?: number;
  longitude?: number;
  hideAddressBox?: boolean;
}

export const WorkplaceMap: React.FC<WorkplaceMapProps> = (params) => (
  <GoogleMap
    workplaces={[
      {
        latitude: params.latitude,
        longitude: params.longitude,
        name: "",
        slug: "",
        description: "",
        phone_number: "",
        address: { city: "", line1: "", postal_code: "", country: "" },
        id: "",
        google_places_id: "",
      },
    ]}
    width="full"
    height={{ base: "410px", lg: "300px" }}
    extraNode={
      !params.hideAddressBox && (
        <DoctorWorkplaceAddress
          name={params.name}
          doctorName={params.doctorName}
          address={params.address}
        />
      )
    }
  />
);
