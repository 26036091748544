import { doctorFullName, doctorWorkplace } from "../shared/helpers/doctors";
import type { Doctor } from "../shared/types";
import { DoctorStructuredData } from "./DoctorStructuredData";
import Head from "next/head";
import { getFrontendTenantConfigurationById } from "../shared/contexts/tenantsConfigurations";
import { useTranslations } from "../shared/i18n/localization";
import { publicAssetUrl } from "../shared/helpers/urls";
import { useRouter } from "next/router";

interface DoctorProfileHeadProps {
  doctor: Doctor;
  tenantId: string;
  baseUrl: string;
}

export const DoctorProfileHead: React.FC<DoctorProfileHeadProps> = ({
  tenantId,
  doctor,
  baseUrl,
}) => {
  const tenantConfiguration = getFrontendTenantConfigurationById(tenantId);
  const { translate, locale } = useTranslations();
  const doctorName = doctorFullName(doctor);
  const { asPath: pathWithoutLocale } = useRouter();
  const url = `${baseUrl}/${locale}${pathWithoutLocale}`;
  const city =
    doctorWorkplace(doctor)?.address?.city ??
    tenantConfiguration.name.toUpperCase();
  const isGkv = doctor.legal_information?.germany?.is_gkv_member ?? false;

  return (
    <>
      <Head>
        <title>
          {translate("doctorProfile.htmlPageTitle", { doctorName, city })}
        </title>
        <meta
          name="description"
          content={translate(
            isGkv
              ? "doctorProfile.metaDescription.gkv"
              : "doctorProfile.metaDescription.default",
            { doctorName }
          )}
        />
        <link rel="canonical" href={url} />
        {tenantConfiguration.enabledPatientLocales.map((supportedLocale) => (
          <link
            key={supportedLocale}
            rel="alternate"
            hrefLang={supportedLocale}
            href={`${baseUrl}/${supportedLocale}${pathWithoutLocale}`}
          />
        ))}
        <link
          rel="icon"
          href={publicAssetUrl(`/${tenantConfiguration.name}/favicon.svg`)}
        />
        <DoctorStructuredData doctor={doctor} itemUrl={url} />
      </Head>
    </>
  );
};
