import * as React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { AddressData } from "../shared/types";

interface DoctorWorkplaceAddressProps {
  name?: string;
  doctorName?: string;
  address: AddressData;
}

export const DoctorWorkplaceAddress: React.FC<DoctorWorkplaceAddressProps> = ({
  name,
  doctorName,
  address,
}) => {
  return (
    <Box
      position="absolute"
      top={4}
      left={4}
      right={{ base: 4, sm: "initial" }}
      p={4}
      bgColor="white"
    >
      <Flex direction="column">
        {name && <Text textStyle="p2">{name}</Text>}
        {doctorName && <Text textStyle="p2">{doctorName}</Text>}
        <Text textStyle="p2">{address.line1}</Text>
        {address.line2 && <Text textStyle="p2">{address.line2}</Text>}
        <Text textStyle="p2">
          {address.postal_code} {address.city}
        </Text>
      </Flex>
    </Box>
  );
};
