import { useContext } from "react";
import {
  Badge,
  Box,
  Heading,
  Image,
  Stack,
  Text,
  useMediaQuery,
  Wrap,
} from "@chakra-ui/react";
import { TenantContext } from "../shared/contexts/tenantContext";
import {
  doctorFullName,
  doctorLanguages,
  doctorWorkplace,
} from "../shared/helpers/doctors";
import { useTranslations } from "../shared/i18n/localization";
import type { Doctor } from "../shared/types";
import { DoctorChoiceButton } from "./DoctorChoiceButton";
import { CrossBulletIcon } from "./icons/CrossBulletIcon";
import { LocationIcon } from "./icons/LocationIcon";
import { TopNavBar } from "./TopNavBar";

interface DoctorProfileHeaderProps {
  doctor: Doctor;
}

export const DoctorProfileHeader: React.FC<DoctorProfileHeaderProps> = ({
  doctor,
}) => {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  return isLargerThan800 ? (
    <DesktopHeader doctor={doctor} />
  ) : (
    <MobileHeader doctor={doctor} />
  );
};

const MobileHeader: React.FC<DoctorProfileHeaderProps> = ({ doctor }) => {
  const { translate } = useTranslations();

  return (
    <Box
      as="header"
      backgroundColor="monochrome.white"
      width="full"
      boxShadow="md"
      zIndex={5}
      marginTop="68px"
    >
      <TopNavBar />
      <Box paddingX={5} marginTop={{ base: "68px", lg: "79px" }}>
        <Stack spacing={3} paddingY={4} align="center">
          <Stack
            direction="row"
            alignSelf="center"
            justify="center"
            align="center"
            spacing={5}
            maxWidth={600}
          >
            <Image
              borderRadius="full"
              boxSize={{ base: "120px", xl: "200px" }}
              src={doctor.display_settings?.photo_urls?.medium}
              alt={translate("doctorProfile.doctor_image_alt_text", {
                doctorName: doctorFullName(doctor),
                location: doctorWorkplace(doctor)?.address?.city,
              })}
              title={translate("doctorProfile.doctor_image_alt_text", {
                doctorName: doctorFullName(doctor),
                location: doctorWorkplace(doctor)?.address?.city,
              })}
              itemProp="image"
            />
            <Stack>
              <CrossBulletIcon boxSize={5} />
              <Heading as="h1" variant="h2" itemProp="name">
                {doctorFullName(doctor)}
              </Heading>
              <DoctorBadges doctor={doctor} />
            </Stack>
          </Stack>
          <Stack spacing={5} align="center" maxWidth={600}>
            <Stack direction="row" alignSelf="start" align="center">
              <LocationIcon />
              <Text color="gray.600" itemProp="workLocation">
                {doctorWorkplace(doctor)?.address?.city}
              </Text>
            </Stack>
            <Box>
              <Heading as="h2" variant="p1">
                {translate("doctorProfile.header.welcomeMessage.title")}
              </Heading>
              <Text>
                {translate("doctorProfile.header.welcomeMessage.body", {
                  doctorName: doctorFullName(doctor),
                })}
              </Text>
            </Box>
          </Stack>
          <Box paddingTop={5}>
            <DoctorChoiceButton doctor={doctor} />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

const DesktopHeader: React.FC<DoctorProfileHeaderProps> = ({ doctor }) => {
  const { translate } = useTranslations();

  return (
    <Stack
      as="header"
      backgroundColor="monochrome.white"
      width="full"
      marginTop="79px"
      boxShadow="md"
      zIndex={5}
      spacing={0}
      alignItems="center"
    >
      <TopNavBar />
      <Stack
        direction="row"
        justify="space-between"
        align="end"
        spacing={8}
        paddingY={5}
        paddingX={4}
        maxWidth="1080px"
        minWidth="800px"
        width="full"
      >
        <Image
          alignSelf="center"
          borderRadius="full"
          boxSize="230px"
          src={doctor.display_settings?.photo_urls?.medium}
          alt={translate("doctorProfile.doctor_image_alt_text", {
            doctorName: doctorFullName(doctor),
            location: doctorWorkplace(doctor)?.address?.city,
          })}
          title={translate("doctorProfile.doctor_image_alt_text", {
            doctorName: doctorFullName(doctor),
            location: doctorWorkplace(doctor)?.address?.city,
          })}
          itemProp="image"
        />
        <Stack spacing={1} width="full">
          <Stack direction="row" justifyContent="space-between">
            <Stack spacing={1}>
              <CrossBulletIcon boxSize={{ base: 5, xl: 7 }} />
              <Heading as="h1" variant="h1" itemProp="name">
                {doctorFullName(doctor)}
              </Heading>
            </Stack>
            <DoctorChoiceButton doctor={doctor} height="fit-content" />
          </Stack>
          <Stack spacing={5}>
            <Stack direction="row" alignSelf="start" align="center">
              <LocationIcon />
              <Text color="gray.600" itemProp="workLocation">
                {doctorWorkplace(doctor)?.address?.city}
              </Text>
              <DoctorBadges doctor={doctor} />
            </Stack>
            <Box>
              <Heading as="h2" variant="p1">
                {translate("doctorProfile.header.welcomeMessage.title")}
              </Heading>
              <Text>
                {translate("doctorProfile.header.welcomeMessage.body", {
                  doctorName: doctorFullName(doctor),
                })}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const DoctorBadges: React.FC<{ doctor: Doctor }> = ({ doctor }) => {
  const { translate } = useTranslations();
  const tenantConfig = useContext(TenantContext);
  const gkvDoctor = doctor.legal_information?.germany?.is_gkv_member ?? false;

  return (
    <>
      {tenantConfig.features.insurances && !gkvDoctor && (
        <Badge height="auto" whiteSpace="normal" textTransform="none">
          {translate("doctorProfile.only_private_cases")}
        </Badge>
      )}
      {tenantConfig.enabledPatientLocales.length > 1 && (
        <Wrap as="ul" spacing={2}>
          {doctorLanguages(doctor).map((language) => (
            <Badge
              as="li"
              key={language}
              textTransform="none"
              itemProp="knowsLanguage"
            >
              {translate(`languages.${language}`)}
            </Badge>
          ))}
          {doctor.can_create_prescription && (
            <Badge
              backgroundColor="primary.50"
              alignSelf="start"
              textTransform="none"
            >
              {translate("doctorProfile.can_create_prescription")}
            </Badge>
          )}
        </Wrap>
      )}
    </>
  );
};
