import { useContext } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Stack,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { TenantContext } from "../shared/contexts/tenantContext";
import { useTranslations } from "../shared/i18n/localization";
import { BurgerMenuIcon } from "./icons/BurgerMenuIcon";
import { CustomCloseIcon } from "./icons/CustomCloseIcon";
import { OnlineDoctorIcon } from "./icons/OnlineDoctorIcon";
import { LanguageSwitcher } from "./LanguageSwitcher";

export const TopNavBar = () => {
  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");
  return isLargerThan1000 ? <DesktopTopNavBar /> : <MobileTopNavBar />;
};

const MobileTopNavBar: React.FC = () => {
  const tenantConfig = useContext(TenantContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { translate, locale } = useTranslations();

  return (
    <Box width="full" zIndex={10} position="fixed" top={0}>
      <Flex
        backgroundColor="monochrome.white"
        justifyContent="space-between"
        align="center"
        paddingX={{ base: 4, xl: "52px" }}
        paddingY={{ base: 3, xl: 4 }}
      >
        <a href={tenantConfig.links.homePage} title="home">
          <OnlineDoctorIcon height={8} width="auto" color="primary.500" />
        </a>
        {isOpen ? (
          <IconButton
            variant="unstyled"
            title="close navigation menu"
            aria-label="close navigation menu"
            icon={<CustomCloseIcon boxSize="24px" onClick={onClose} m={2} />}
          />
        ) : (
          <IconButton
            variant="unstyled"
            title="open navigation menu"
            aria-label="open navigation menu"
            icon={<BurgerMenuIcon boxSize="40px" onClick={onOpen} />}
          />
        )}
      </Flex>
      <Box backgroundColor="primary.500" width="full" height={1} />
      {isOpen && (
        <Stack
          position="fixed"
          top="62px"
          zIndex={10}
          width="full"
          backgroundColor="monochrome.white"
          boxShadow="md"
          spacing={0}
          divider={
            <Divider
              backgroundColor="monochrome.super-light-gray"
              height="1px"
            />
          }
        >
          {tenantConfig.links.navigationMenu.map((menuItem) => (
            <Button
              as="a"
              key={menuItem.name}
              href={`${menuItem.url[locale]}`}
              whiteSpace="nowrap"
              justifyContent="start"
              paddingX={10}
              paddingY={5}
              variant="tertiary"
              width="full"
              _hover={{ bg: "background.light-ink" }}
            >
              {translate(menuItem.name)}
            </Button>
          ))}
          {tenantConfig.enabledPatientLocales.length > 1 && (
            <Box paddingX={10} paddingY={5}>
              <LanguageSwitcher style="buttons" />
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
};

const DesktopTopNavBar: React.FC = () => {
  const tenantConfig = useContext(TenantContext);
  const { translate, locale } = useTranslations();

  return (
    <Box
      position="fixed"
      top={0}
      width="full"
      zIndex={10}
      backgroundColor="monochrome.white"
    >
      <Flex
        justifyContent="space-between"
        align="center"
        paddingX={{ base: 4, xl: "52px" }}
        paddingY={{ base: 3, xl: 4 }}
        height="75px"
      >
        <a href={tenantConfig.links.homePage} title="home">
          <OnlineDoctorIcon height={8} width="auto" color="primary.500" />
        </a>
        <Stack direction="row" align="center" spacing={10}>
          {tenantConfig.links.navigationMenu.map((menuItem) => (
            <Button
              as="a"
              whiteSpace="nowrap"
              key={menuItem.name}
              href={`${menuItem.url[locale]}`}
              variant="tertiary"
            >
              {translate(menuItem.name)}
            </Button>
          ))}
          {tenantConfig.enabledPatientLocales.length > 1 && (
            <LanguageSwitcher />
          )}
        </Stack>
      </Flex>
      <Box backgroundColor="primary.500" width="full" height={1} />
    </Box>
  );
};
