import { createIcon } from "@chakra-ui/icons";

export const LocationIcon = createIcon({
  displayName: "location",
  viewBox: "0 0 48 48",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.655 47.9061c-.3154-.1879-.5678-.4071-.757-.6576-.0631 0-.5678-.5479-1.5141-1.6438-.9463-1.0959-2.1607-2.6145-3.6432-4.5558-1.4825-1.9413-2.965-4.0704-4.4475-6.3875-1.4826-2.317-2.8073-4.9001-3.9744-7.7495-1.16713-2.8493-1.87683-5.4951-2.12918-7.9373-.12617-.7515-.18925-1.4717-.18925-2.1605 0-4.6341 1.65598-8.59491 4.96803-11.8826C16.2804 1.64382 20.2863 0 24.9862 0c4.6999 0 8.7058 1.64382 12.0178 4.9315 3.312 3.28769 4.968 7.2485 4.968 11.8826 0 .6262-.0631 1.3151-.1892 2.0665-.2524 2.5049-.9779 5.1977-2.1765 8.0783-1.1986 2.8806-2.4603 5.4012-3.7851 7.5616-1.3248 2.1605-2.8231 4.3523-4.4949 6.5754-1.6718 2.2231-2.8231 3.7104-3.4539 4.4618-.6309.7515-1.1356 1.3151-1.5141 1.6908-.2523.2505-.5047.4697-.757.6576-.1893.0626-.347.0939-.4732.0939-.1261 0-.2838-.0313-.4731-.0939Zm6.3403-29.9648c0 3.3202-2.6916 6.0117-6.0118 6.0117s-6.0117-2.6915-6.0117-6.0117c0-3.3202 2.6915-6.0117 6.0117-6.0117 3.3202 0 6.0118 2.6915 6.0118 6.0117Z"
    />
  ),
  defaultProps: {
    fill: "primary.500",
  },
});
