import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const CustomChevronLeftIcon = createIcon({
  displayName: "CustomChevronLeftIcon",
  viewBox: "0 0 14 23",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.40257 9.37745.28125 11.4988l2.12132 2.1213 9.19243 9.1924 2.1213-2.1213-9.19241-9.1924 9.19241-9.19242L11.595.185059 2.40257 9.37745Z"
    />
  ),
  defaultProps: {
    fill: "primary.500",
  },
});
